import Vue from 'vue'
import VueRouter from 'vue-router'
import UsersList from '../components/User/UsersList.vue'
import EditarUser from '../components/User/EditarUser.vue'
import AdminPage from '../components/Admin/AdminPage.vue'
import FogotPassword from '../components/Admin/FogotPassword.vue'
import PassRecover from '../components/Admin/PassRecover.vue'
import AdminLogin from '../components/Admin/AdminLogin.vue'
import RegistAdmin from '../components/Admin/RegistAdmin.vue'

import EmpresasList from '../components/Empresa/EmpresasList.vue'
import VerEmpresa from '../components/Empresa/VerEmpresa.vue'
import CreateEmpresa from '../components/Empresa/CreateEmpresa.vue'
import EditarEmpresa from '../components/Empresa/EditarEmpresa.vue'

import ProdutosList from '../components/Produto/ProdutosList.vue'
import EditarProduto from '../components/Produto/EditarProduto.vue'
import CreateProduto from '../components/Produto/CreateProduto.vue'
import InfoPage from '../components/User/InfoPage.vue'

import PrimeRate from '../components/Produto/PrimeRate.vue'
import HomePage from '../components/Menu/HomePage.vue'
// import HeaderComp from '../components/Menu/HeaderComp.vue'
import Main from '../components/Main.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [

  {
    path: '/home',
    metaL: { requiresAuth: true},
    component: Main,
    children: [
      {
        path: '/home',
        name: 'home',
        component: HomePage
      },
      {
        path: '/registar',
        name: 'Registar_admin',
        component: RegistAdmin
      },
      {
        path: '/empresas',
        name: 'ListaEmpresas',
        component: EmpresasList
      },
      {
        path: '/empresas/:id',
        name: 'verEmpresa',
        component: VerEmpresa,
      },
      {
        path: '/editar/:id',
        name: 'editarEmpresa',
        component: EditarEmpresa,
      },
     
      {
        path: '/CriarEmpresa',
        name: 'criar',
        component: CreateEmpresa,
      },
      // ===========================================================================
      {
        path: '/produtos',
        name: 'produtos',
        component: ProdutosList,
      },
      {
        path: '/editarProduto/:id',
        name: 'editarProduto',
        component: EditarProduto,
      },
      {
        path: '/criarproduto/:id',
        name: 'CriarProduto',
        component: CreateProduto,
      },
      {
        path: '/users',
        name: 'users',
        component: UsersList,
      },
      {
        path: '/editar/:id',
        name: 'editarUser',
        component: EditarUser,
      },
      // ========================================================================  
      {
        path: '/primerate',
        name: 'prime_rate',
        component: PrimeRate,
      },
      {
        path: '/admin',
        name: 'admin',
        component: AdminPage,
      },
      // ========================================================================
    ]
  },
  
  
  // ========================== free routes ==============================
  {
    path: '/info',
    name: 'info_page',
    component: InfoPage,
  },
  {
    path: '/fogot',
    name: 'fogot_pass',
    component: FogotPassword,
  },
  
  {
    path: '/',
    name: 'Admin_login',
    component: AdminLogin
  },
  {
    path: '/recover/:token',  
    name: 'recover_pass',
    component: PassRecover,
    props:true
  },
  // =============================================
  
 
  

  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// let originalRoute = null;
router.beforeEach((to, from, next) => {
  // Verifica se a rota requer autenticação
  if (to.matched.some(route => route.meta.requiresAuth)) {
    const token = localStorage.getItem('token');
    if (!token) {
      // originalRoute = to.path;
      store.commit('setRedirectTo', to.fullPath);
      next('/');
    } else {
      // Se o usuário estiver autenticado, permita o acesso à rota privada
      next();

    }
  } else {
    // Se a rota não requer autenticação, permita o acesso normalmente
    next();
  }
});


export default router
