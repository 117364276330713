<template>
  <div style="margin:20px auto; width: 80%;">
    
    <!-- <router-link to="/registar">
      <v-btn class="ma-2" color="green lighten-2">
        <v-icon dark left>
          mdi-plus
        </v-icon>add
      </v-btn>
    </router-link> -->
    <router-link to="/registar">
      <v-btn class="ma-2" color="green lighten-2">
        <v-icon dark left>
          mdi-plus
        </v-icon>Adiconar Admin
      </v-btn>
    </router-link>

    <div>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="6">
              <h3>Lista dos Admins</h3>
            </v-col>
            <v-col cols="6">
              
              <v-text-field v-model="search" append-icon="mdi-magnify" placeholder="Pesquisar Admins . . ." single-line
                hide-details style="width: 1000px; margin: auto; padding: 10px;"></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table :headers="headers" :search="search" :items="users" class="elevation-1" :loading=dialog>
        <template v-slot:[`item.actions`]="{ item }">
          <div style="display: flex;">
            <v-btn @click="editarUser(item)" style="margin: 2px;">
              <img width="25" height="25" src="https://img.icons8.com/ios/100/000000/edit--v1.png" alt="edit--v1" />
            </v-btn>
            <v-btn @click="apagarUser(item)" style="margin: 2px;">
              <img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/000000/trash--v1.png"
                alt="trash--v1" /> </v-btn>
          </div>
          <template>
            <div class="text-center">
              <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                  <v-card-text>
                    Aguarde...
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </template>
        </template>
      </v-data-table>
      </v-card>
      
    </div>
    <!-- <UserComp :users="users"/> -->
  </div>
</template>

<script>
import axios from "axios";
import BASE_ULR from "../../config/api";
import Swal from 'sweetalert2';
// import UserComp from "../User/UserComp.vue";

export default {
  name: "UsersList",

  components: {
    // UserComp
  },
  data() {
    return {
      url: `${BASE_ULR}/user`,
      dialog: true,
      headers: [
        { text: 'Id', value: 'id' },
        {
          text: 'Nome',
          sortable: false,
          value: 'nome',
        },
        { text: 'Apelido', value: 'apelido' },
        { text: 'Email', value: 'email' },
        { text: 'Criado em', value: 'created_at' },
        { text: 'Atualizado em', value: 'updated_at' },
        { text: 'Acoes', value: 'actions', sortable: false },
      ],
      users: [],
      search: ''
    };
  },
  methods: {
    getUsers() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      }
      axios.get(this.url, config).then(data => {
        this.users = data.data.users;
        if (this.users) {
          this.dialog = false;
        }
      }).catch((error) => {
        if (error == 401) {
          localStorage.clear();
          console.error("Error submitting data:", error);
          this.$router.push({ name: 'Admin_login' });
        }
      }
      );
    },
    apagarUser(item) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      }
      axios.delete(`${this.url}/${item.id}`, config)
        .then(response => {
          console.log(response);
          location.reload();
        })
        .catch(error => {
          console.log(error);
        });
        Swal.fire({
          title: "Sucesso!",
          text: "Apagado com sucesso!",
          icon: "success",
        })
    },
    editarUser(item) {
      this.$router.push({ name: 'editarUser', params: { id: item.id } });
    },
  },
  created() {
    // while(!this.users){
    // this.dialog=true;
    // }
    this.getUsers();
  }
};
</script>
