<template>
  <div style="border: 0; margin: 0; padding: 0; box-sizing: border-box;">
    <template>
      <v-row>
        <v-col cols="6">
          <div style="  justify-content: space-around;">
            <!-- <PrimeRate style="margin:20px ;" /> -->
            <P style="margin:20px">Prime Rate: {{ primerate }}</P>
          </div>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="search" append-icon="mdi-magnify" placeholder="Pesquisar Empresas . . ." single-line
            hide-details style="width: 1000px; margin: auto; padding: 10px;"></v-text-field>

        </v-col>
      </v-row>
      <EmpresasList style="position: relative;" :search="search" />
    </template>
  </div>
</template>

<script>
import EmpresasList from "../Empresa/EmpresasList.vue";
import BASE_URL from "../../config/api";
// import PrimeRate from "../Produto/PrimeRate.vue";
import axios from "axios";
// import { mapGetters, mapActions } from 'vuex';


export default {
  name: "AdminPage",
  components: {
    EmpresasList,
    // PrimeRate
  },
  data() {
    return {
      visivel: false,
      url: `${BASE_URL}/primerate`,
      hide: true,
      search: '',
      primerate: ""
    }
  },


  methods: {
    // ...mapActions(['apdateSerchEmpresas']),

    showCriar() {
      this.visivel = true;
    },
    cancel() {
      this.visivel = false;
    },
    handleEvento(data) {
      this.hide = !data;
      console.log(data);
    },
    getPrimerate() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      }
      axios.get(`${this.url}`, config)
        .then((response) => {
          // Fill the form fields with the fetched data
          const primerat = response.data.primerate[0].primerate;
          this.primerate = primerat;
        })
        .catch((error) => {
          console.error("Error submitting data:", error);
          if (error == 401) {
            localStorage.clear();
            this.$router.push({ name: 'Admin_login' });
          }
        }
        );
    },
    
  },
  created(){
    this.getPrimerate();  
  }

};
</script>
