<template>
    <div style="width: 400px; margin:50px  auto;">
        <div style="width: 100px; height: 100px; margin: auto;">
            <img width="100" height="100" src="https://img.icons8.com/ios-filled/100/000000/guest-male--v1.png"
                alt="guest-male--v1" />
        </div>
        <form style="width: 300px; margin: 10px auto;">
            <v-text-field v-model="newpass" label="nova password" required type="password"></v-text-field>
            <v-text-field v-model="repetpass" label="repitir password" required type="password"></v-text-field>
            <v-btn class="mr-4" @click="submit">Redefinir</v-btn>
        </form>
        <v-alert style="text-align: center;" border="top" color="red lighten-2" dark v-show=visivel>
            Senhas nao conscidem !
        </v-alert>
    </div>
</template>

<script>
import axios from "axios";
import BASE_URL from "../../config/api";
export default {
    name: "FogotPassword",
    data() {
        return {
            email: null,
            visivel: false,
            newpass: null,
            repetpass: null,
            url: `${BASE_URL}/user/recover/password`,
            token: this.$route.params.token
        }
    },
    methods: {
        submit() {
            localStorage.setItem('token', this.token)
            const config = {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                }
            }
            if (this.newpass === this.repetpass) {
                axios.post(`${this.url}`, { "newpass": this.newpass }, config)
                    .then((response) => {
                        console.log(response);
                        localStorage.setItem("token", response.data[0]);
                        var originalRoute = this.$store.state.redirectTo;
                        if (originalRoute) {
                            this.$router.push(originalRoute);
                        }
                        else {
                            this.$router.push("/home");
                        }
                    })
                    .catch((error) => {
                        console.error("Error submitting data:", error);
                        if (error == 401) {
                            this.$router.push({ name: 'Admin_login' });
                        }
                    }
                    );
            } else {
                this.visivel = true;
            }
        }
    },
}
</script>