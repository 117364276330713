<template>
    <div style="margin: 50px auto; width: 800px; font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
        <v-btn class="ma-2" style="background-color: #edb664;color: black;" dark @click="voltar">
            <v-icon dark left>
                mdi-arrow-left
            </v-icon>Voltar
        </v-btn>
        <v-card elevation-2>
            <h1 style="padding: 0 50px;">Empresa</h1>
            <div
                style="display: flex; flex-wrap: wrap; padding: 30px; flex-direction: row; justify-content: space-between; box-sizing: border-box;">
                <div>
                    <p>Nome: {{ nome }}</p>
                    <p>Dominio: {{ dominio }}</p>
                    <p>Telefone: {{ telefone }}</p>
                    <!-- <v-file-input  type="file" @change="onFileChange" accept="image/*"></v-file-input> -->
                    <div style="display: flex; flex-direction: row; justify-content:center;">
                        <v-btn class="mr-4" style="margin: 5px 0; width: 100px;" @click="EditComp"
                            color="orange accent-1">Editar</v-btn>

                        <template>
                            <v-row justify="center">
                                <v-dialog v-model="dialog1" persistent max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn style="margin:5px 0; width: 100px;" color="deep-orange lighten-1"
                                            v-bind="attrs" v-on="on">Apagar</v-btn>
                                        <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on"> -->
                                        <!-- Open Dialog -->
                                        <!-- </v-btn> -->
                                    </template>
                                    <v-card>
                                        <v-card-title class="text-h5" style="color: rgb(255, 90, 90);">
                                            Apagar Empresa...
                                        </v-card-title>
                                        <v-card-text>
                                            <h2><em>Tem a certeza que deseja apagar esta Empresa?</em></h2>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="green darken-1" text @click="dialog1 = false">
                                                Cancelar
                                            </v-btn>
                                            <v-btn color="green darken-1" text @click="DeleteComp">
                                                Apagar
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-row>
                        </template>
                    </div>
                </div>
                <div>
                    <img v-if="logoPath" :src="logoPath" alt="Logo" width="200px" height="200px" title="Logo">
                    <h6>Logotipo</h6>
                </div>
            </div>
        </v-card>
        <v-card elevation-2 title="Produtos">
            <ProdutosList :id=id.toString()></ProdutosList>
        </v-card>
        <template>
            <div class="text-center">
                <v-dialog v-model="dialog" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                        <v-card-text>
                            Aguarde...
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </template>
    </div>
</template>

<script>
import axios from "axios";
import BASE_URL from "../../config/api";
import ProdutosList from "../Produto/ProdutosList.vue";
import Swal from 'sweetalert2';

export default {
    components: {
        ProdutosList
    },
    data() {
        return {
            url: `${BASE_URL}/empresas/`,
            nome: "",
            dominio: "",
            telefone: null,
            logoPath: null,
            logo: null,
            id: null,
            dialog: false,
            dialog1: false,
        }
    },

    methods: {
        insert() {
            const config1 = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }
            this.id = this.$route.params.id;
            axios.get(this.url + this.id, config1).then(data => {
                this.nome = data.data.produto.nome;
                this.dominio = data.data.produto.dominio;
                this.telefone = data.data.produto.telefone;
                this.logoPath = `${BASE_URL}/`+ data.data.produto.logotipo;
                // console.log(data.data.produto.logo)
                // outros dados a serem impressos na tela
            });
        },

        DeleteComp() {            
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }
            this.dialog = true;
            const id = this.$route.params.id;
            axios.delete(`${this.url}${id}`, config)
                .then(response => {
                    console.log(response);
                    this.dialog = false;
                    Swal.fire({
                        title: "Apagado!",
                        text: "Empresa Apagada com sucesso!",
                        icon: "success",
                    });
                    this.voltar();
                })
                .catch((error) => {
                    console.error("Error submitting data:", error);
                    Swal.fire({
                        title: "Erro!",
                        text: "Algo deu errado!",
                        icon: "error",
                    });
                    if (error == 401) {
                        localStorage.clear();
                        this.$router.push({ name: 'Admin_login' });
                    }
                }
                );
            // this.dialog = false;
        },
        EditComp() {
            const id = parseInt(this.$route.params.id);
            this.$router.push({ name: 'editarEmpresa', params: { id: id } });
        },

        onFileChange(event) {
            // console.log("onFileChange acionado!");
            this.logo = event.target.files[0]; // Store the selected image file
        },
        voltar() {
            this.$router.push({ name: 'home' });
        }
    },
    created() {
        this.dialog = true;
        this.insert();
        this.dialog = false;
    }
}
</script>
