import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    redirectTo: null, // Armazena a rota originalmente acessada antes do redirecionamento para o login
    cont:false,
    load: false
    // dialog:true
  },
  mutations: {
    toggleCont(state) {
      state.cont = !state.cont; // Inverte o valor atual de 'cont'
    },
    // toggleDialog(state) {
    //   state.dialog = !state.dialog; // Inverte o valor atual de 'dialog'
    // },

    setRedirectTo(state, value) {
      state.redirectTo = value;
    },   
  
  },
 
  getters: {
    // primerateEdit: state => state.primerateEdit,
    getCount: (state) => state.cont,
    // getDialog: (state) => state.dialog,
  },
  
 
})
