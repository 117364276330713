<template>
  <div >
    <router-link to="/home" >
      <v-btn class="ma-2" style="background-color: #edb664;color: black; margin: 20px !important;" dark >
        <v-icon dark left > 
          mdi-arrow-left
        </v-icon>Back
      </v-btn>
    </router-link>
    <v-card class="mx-auto my-2" style="padding: 20px !important;"
    max-width="544"
     elevation="12">
      <div class="logotipo">
         <img v-if=!logolink class="logoimg" :src=logoLink > 
         <img class="logoicon" width="48" height="48" src="https://img.icons8.com/color/48/000000/add-image.png" alt="add-image"/>
         <input class="file" type="file" @change="onFileChange" accept="image/*">
      </div>
      <form  class="form" style=" margin: 30px auto;" @submit.prevent="submitForm" enctype="multipart/form-data">
        <v-text-field v-model="nome" label="Nome" required></v-text-field>
        <v-text-field v-model="dominio" label="Dominio" required></v-text-field>
        <v-text-field v-model="telefone" label="Telefone" required type="number"></v-text-field>
        <v-btn type="submit" class="mr-4" color="green lighten-2">Submeter</v-btn>
        <v-btn @click="clearForm">Limpar</v-btn>
      </form>
    </v-card>
    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Aguarde...
              <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import BASE_URL from "../../config/api";
import Swal from 'sweetalert2';

export default {
  name: "CreateEmpresa",

  data() {
    return {
      url: `${BASE_URL}/empresas`,
      nome: "",
      dominio: "",
      telefone: null,
      logo: null,
      logoLink: null,
      dialog: false,
    };
  },
  methods: {
    submitForm() {
      const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      }
      this.dialog = true;
      const formData = new FormData();
      formData.append("nome", this.nome);
      formData.append("dominio", this.dominio);
      formData.append("telefone", this.telefone);
      formData.append("logo", this.logo);
      axios
        .post(this.url, formData, config)
        .then((response) => {
          // Handle success, e.g., show a success message or redirect to a new page
          console.log("Data successfully submitted:", response.data);
          this.clearForm();
          this.dialog = false;
          Swal.fire({
                        title: "Criado!",
                        text: "Empresa Criada com sucesso!",
                        icon: "success",
                    });
          this.$router.push("/home");
        })
        .catch((error) =>{
          Swal.fire({
                        title: "Ups!",
                        text: "Erro ao criar Empresa!",
                        icon: "error",
                    });
          console.error("Error submitting data:", error);
          if(error==401){
            this.$router.push({ name: 'Admin_login' });
          }
        } 
        );
        this.dialog = false;
    },
    clearForm() {
      this.nome = "";
      this.dominio = "";
      this.telefone = null;
      this.logo = null;
    },
    onFileChange(event) {
      this.logo = event.target.files[0]; // Store the selected image file
      if(this.logo){
        this.logoLink=URL.createObjectURL(this.logo);
      }
     // else{
     //   this.logoLink=require('@/assets/addimage.jpg');
     // }
    },
  },
};
</script>

<style scoped>
.logotipo{
  /* background-color: aqua; */
  height: 180px;
  width: 100%;
  align-items: center;
  
  /* justify-content: ; */
}
.logoimg{
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* position: absolute; */
  /* z-index: 111 !important; */
}
.logoicon{
 position:absolute;
 top:90px;
 left: 250px;
}
.file{
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: blue; */
  width: 100%;
  height: 180px;
  opacity: 1;
  z-index: 999;
  margin: auto !important;
  opacity: 0;
}
</style>