<template>
    <v-app>
      <!-- <HeaderComp/> -->
      <HeaderComp2/>
      <!-- <MenuComp/> -->
      <!-- <ProcessorView/> -->
      <v-main>
        
        <!-- <AdminPage/>       -->
          <router-view/>
      </v-main>
    </v-app>
  </template>
  
  <script>
//   import HeaderComp from './Menu/HeaderComp.vue';
  import HeaderComp2 from './Menu/Header2.vue';
  
  // import ProcessorView from './views/ProcessorView.vue';
  
  
  export default {
    name: 'App',
    components:{
    //   HeaderComp,
      HeaderComp2
    },  
  };
  </script>