<template>
  <div>

    <form class="formulario" style="width: 500px; margin: 10px auto;">
      <v-text-field v-model="nome" label="Nome" required></v-text-field>
      <v-text-field v-model="descricao" label="Descricao" required></v-text-field>
      <v-text-field v-model="taxa_max" label="taxa maxima" required></v-text-field>
      <v-text-field v-model="taxa_min" label="taxa minima" required></v-text-field>
      <v-text-field v-model="prazo_max" label="Prazo maximo" required></v-text-field>
      <v-text-field v-model="prazo_min" label="Prazo minimo" required></v-text-field>
      <v-text-field v-model="valor_max" label="valor maximo" required></v-text-field>
      <v-text-field v-model="valor_min" label="valor minimo" required></v-text-field>
      <v-alert dense outlined type="error" v-if="tvisible" >{{ texto }}</v-alert>
      <div style="display: flex;">
        <v-btn class="mr-4" @click="submitForm" style="margin: 10px;" color="green lighten-2">Salvar</v-btn>
        <v-btn @click="clearForm" style="margin: 10px;" color="orange lighten-2">Limpar</v-btn>
        <v-btn @click="cancelar" style="margin: 10px;" color="yellow lighten-3">Cancelar</v-btn>
      </div>
    </form>
    
        <div class="text-center">
          <v-dialog v-model="dialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text>
                Aguarde...
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
  </div>
    
</template>
  
<script>
import axios from "axios";
import BASE_URL from "../../config/api";
import Swal from 'sweetalert2';

export default {
  name: "UptadeEmpresa",

  data() {
    return {
      tvisible: false,
      texto: '',
      url: `${BASE_URL}/produtos`,
      nome: "",
      descricao: "",
      taxa_max: null,
      taxa_min: null,
      prazo_max: null,
      prazo_min: null,
      valor_max: null,
      valor_min: null,
      logo: null,
      empresaId: this.$route.params.id,
      dialog:false
    };
  },

  methods: {
    submitForm() {
      if(!this.ehVazio()){
        const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      }
      this.dialog=true;
      const formData = new FormData();
      formData.append("nome", this.nome);
      // formData.append("bem", this.bem);
      formData.append("descricao", this.descricao);
      formData.append("taxa_min", this.taxa_min);
      formData.append("taxa_max", this.taxa_max);
      console.log("Taxa minima: " + this.taxa_min);
      formData.append("prazo_max", this.prazo_max);
      formData.append("prazo_min", this.prazo_min);
      formData.append("max", this.valor_max);
      formData.append("min", this.valor_min);
      formData.append("empresa_id", this.empresaId);
      
      axios.post(`${this.url}`, formData, config)
        .then((response) => {
          // Handle success, e.g., show a success message or redirect to a new page
          console.log("Data successfully submitted:", response.data);
          this.clearForm();
          Swal.fire({
                        title: "Criado!",
                        text: "Produto criado com sucesso!",
                        icon: "success",
                    });
          this.$router.push("/empresas/" + this.empresaId);
          })
        .catch((error) => {
          Swal.fire({
                      title: "Ups!",
                      text: "Erro ao criar produto!",
                      icon: "error",
                  });
                  console.error("Error submitting data:", error);
          if (error == 401) {
            localStorage.clear();
            this.$router.push({ name: 'Admin_login' });
          }
        }
        );
      }
      
    },
    clearForm() {
      this.nome = "";
      // this.bem = "";
      this.descricao = "";
      this.taxa_max = "";
      this.taxa_min = "";
      this.valor_max = "";
      this.valor_min = "";
      this.prazo_max = "";
      this.prazo_min = "";
    },
    ehVazio() {
      if ((this.nome == null || this.nome == "") || (this.descricao == null || this.descricao == "") ||
        (this.taxa_max == null || this.taxa_max == "") || (this.taxa_min == null || this.taxa_min == "") ||
        (this.valor_max == null || this.valor_max == "") || (this.valor_min == null || this.valor_min == "") ||
        (this.prazo_max == null || this.prazo_max == "") || (this.prazo_min == null || this.prazo_min == "")) {
          this.tvisible = true;
          this.texto="Preecha todos os campos"            
          setInterval(() => {
            this.tvisible=false;
          }, 5000);
        return true;
      }else{
        return false;
      }
    },
    cancelar() {
      this.$router.push({ name: 'verEmpresa', params: { id: this.empresaId } });
    },
    // onFileChange(event) {
    //   this.logo = event.target.files[0]; // Store the selected image file
    // },
  },
};
</script>
<style scoped>
.formulario {
  background-color: rgb(255, 247, 216);
  padding: 30px;
}
</style>