<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<v-card
		
		tile
	>
		<v-app-bar
		color="#deac62"
		dark
		>
		<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

		<v-toolbar-title>Marrar</v-toolbar-title>
		<v-spacer></v-spacer>
		<v-toolbar-items>
			<v-menu
			offset-y
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" depressed rounded text small>
						<div>
							{{email}}
						</div>
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click.prevent="logout()">
						<v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
						<v-list-item-title>Logout</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>

		</v-toolbar-items>
		</v-app-bar>

		<v-navigation-drawer
		color="#deac62"
		v-model="drawer"
		fixed
		clipped
		app
		enable-resize-watcher
		height="100vh"
		>
		<!--temporary-->
		<v-list
			nav
			dense
		>
			<v-list-item>
			
				<v-app-bar elevation="0" color="#deac62">
				
				
					<v-app-bar-nav-icon style="color:white;" @click="drawer = !drawer"></v-app-bar-nav-icon>
				
                    <v-toolbar-title style="color:white;">Marrar</v-toolbar-title>
				</v-app-bar>

			</v-list-item>
            <v-list-item to="/home">
                <v-list-item-icon>
					<v-icon color="white">mdi-office-building</v-icon>
				</v-list-item-icon>
                <v-list-item-title style="color:white;">Empresas</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item to="/registar">
                <v-list-item-icon>
					<v-icon color="white">mdi-account-plus</v-icon>
				</v-list-item-icon>
                <v-list-item-title style="color:white;">Add Admin</v-list-item-title>
            </v-list-item> -->
            <v-list-item to="/users">
                <v-list-item-icon>
                    <v-icon color="white">
                        mdi-menu
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-title style="color:white;">
                    Ver Admin
                </v-list-item-title>
            </v-list-item>
            <!-- <v-list-item to="/CriarEmpresa">
                <v-list-item-icon>
                    <v-icon color="white">
                        mdi-office-building-plus
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-title style="color:white;">
                    Empresa
                </v-list-item-title>
            </v-list-item> -->
            <v-list-item @click="toggleCont">
                <v-list-item-icon>
                    <v-icon color="white">
                        mdi-pencil
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-title style="color:white;">
                    PrimeRate
                </v-list-item-title>
            </v-list-item>
			
		</v-list>
		</v-navigation-drawer>
	</v-card>
</template>


<script>
import { mapState, mapMutations } from 'vuex';
import axios from 'axios'
import BASE_URL from "../../config/api";

export default {
    data() {
        return {
			nome: localStorage.getItem('nome'),
            email: localStorage.getItem('email'),
            url: `${BASE_URL}/user/logout/sair `,
			drawer: true,
			group: null,
            user: {
                initials: 'MM',
                fullName: 'Milice',
                email: 'milice@marrar.co.mz',
            },
        }
    },
	methods: {
		...mapMutations(['toggleCont']),
		logout() {
            this.$store.state.load = true
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }

            this.loading1 = true
            axios.get(this.url, config)
                .then((response) => {
                    // Handle success, e.g., show a success message or redirect to a new page
                    console.log("Data successfully submitted:", response.data);
                    localStorage.removeItem('token');
                    this.$router.push('/');
                    location.reload();
                    this.$store.state.load = false
                })
                .catch((error) => {
                    console.error("Error submitting data:", error);
                    if (error == 401) {
                        this.$router.push({ name: 'Admin_login' });
                    }
                }
                );
        },

	},
	computed: {
        ...mapState(['cont']),
		// user() {
		// 	const user = this.$store.state.auth.user

		// 	return user
		// }
	}
}
</script>

<style>

</style>