<template>
    <div style="width: 300px; margin:50px  auto;">
        <div style="width: 100px; height: 100px; margin: auto;">
            <img width="100" height="100" src="https://img.icons8.com/ios-filled/100/000000/guest-male--v1.png"
                alt="guest-male--v1" />
        </div>
        <form class="form" style="width: 300px; margin: 10px auto;" v-if=!visivel>
            <v-text-field v-model="nome" label="Nome" required type="text"></v-text-field>
            <v-text-field v-model="email" label="Email" required type="email"></v-text-field>
            <v-text-field v-model="apelido" label="Apelido" required type="text"></v-text-field>
            <v-text-field v-model="password" label="password" required type="password"></v-text-field>
            <router-link to="/users">
                <v-btn class="mr-4" color="green lighten-2">Voltar</v-btn>
            </router-link>
            <v-btn @click="submit" color="orange lighten-2">Registar</v-btn>
        </form>
        <v-alert style="text-align: center;" border="top" color="green lighten-2" dark v-if=visivel>
            {{ texto }}
            <router-link to="/users">
      <v-btn class="ma-2" color="indigo lighten-2" dark>
        <v-icon dark left>
          mdi-arrow-left
        </v-icon>Back
      </v-btn>
    </router-link>
        </v-alert>
        <template>
            <div class="text-center">
                <v-dialog v-model="dialog" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                        <v-card-text>
                            Aguarde...
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </template>
    </div>
</template>

<script>
import axios from "axios";
import BASE_URL from "../../config/api";
import Swal from 'sweetalert2';

export default {
    name: "editarUser",
    data() {
        return {
            url: `${BASE_URL}/user`,
            email: null,
            password: null,
            nome: null,
            apelido: null,
            visivel: false,
            texto: null,
            dialog: false,
            userId: this.$route.params.id
        }
    },
    methods: {
        submit() {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }
            this.dialog = true;
            const formData = new FormData();
            formData.append("nome", this.nome);
            formData.append("email", this.email);
            formData.append("password", this.password);
            formData.append("apelido", this.apelido);

            axios
                .post(this.url +'/'+ this.userId, formData, config)
                .then((response) => {
                    // Handle success, e.g., show a success message or redirect to a new page
                    console.log("Data successfully submitted:", response.data);
                    this.texto = "Editado com sucesso!";
                    this.visivel = true;
                    this.dialog = false;
                    Swal.fire({
                        title: "Sucesso",
                        text: "User editado com sucesso!",
                        icon: "success",
                    });
                })
                .catch((error) => {
                    this.texto = "sem sucesso!";
                    this.visivel = true;
                    this.dialog = false;
          Swal.fire({
                      title: "Ups!",
                      text: "Erro ao editar User!",
                      icon: "error",
                  });
                  console.error("Error submitting data:", error);
          if (error == 401) {
            localStorage.clear();
            this.$router.push({ name: 'Admin_login' });
          }
        });
        },
        fetchProdutoData() {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }
            // Assuming you have an API endpoint to fetch product data by ID
            axios.get(`${this.url}/${this.userId}`, config)
                .then((response) => {
                    // Fill the form fields with the fetched data
                    const userData = response.data.user;
                    this.nome = userData.nome;
                    this.apelido = userData.apelido;
                    this.password = userData.password;
                    this.email = userData.email;
                    // Depending on how you store the logo, you may need to handle it differently
                })
                .catch((error) => {
                    // Handle error, e.g., show an error message
                    console.error("Error submitting data:", error);
                    this.texto = "Sem sucesso!";
                    this.visivel = true;
                    this.dialog = false
                });
        },

    },
    created() {
        if (this.userId) {
            this.fetchProdutoData();
        }
    },
}

</script>
<style scoped>
.form{
    background-color: rgb(255, 241, 223);
    padding: 40px;
}
</style>