<template>
  <v-app>
      <PrimeRate style="margin:20px ;" />
      <!-- <AdminPage/>       -->
        <router-view/>
    <loader/>
  </v-app>
</template>

<script>
import Loader from './components/loader/loader.vue'
import PrimeRate from './components/Produto/PrimeRate.vue'
// import ProcessorView from './views/ProcessorView.vue';

export default {
  name: 'App',
  components:{
    Loader,
    PrimeRate
  },
  methods:{
    // validate(){
    //   axios.get('http://127.0.0.1:8000/api/login').then((response)=>{
    //     console.log(response);
    //   })
    // }
  },
  created(){
    // this.validate();
  }

};
</script>