<template>
    <div style="padding: 10px; margin: 10px;">
        <h1>Produtos</h1>
        <v-btn color="teal lighten-3" @click="criarProduto()" style="margin: 10px 0;">+ Criar Produto</v-btn>
        <!-- <ProdutoComp :produtos="produtos" /> -->
        <v-data-table :headers="headers" :items="produtos" class="elevation-1" :loading=dialog
            loading-text="Carregando Produtos...">
            <template v-slot:[`item.actions`]="{ item }">
                <div style="display: flex;">
                    <v-btn @click="editarProduto(item)" style="margin: 2px;">
                        <img width="25" height="25" src="https://img.icons8.com/ios/100/000000/edit--v1.png"
                            alt="edit--v1" />
                    </v-btn>
                    <v-btn @click="apagarProduto(item)" style="margin: 2px;">
                        <img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/000000/trash--v1.png"
                            alt="trash--v1" /> </v-btn>
                </div>
                <!-- <ProcessorView/> -->
            </template>

        </v-data-table>
    </div>
</template>
  
<script>
import axios from "axios";
import BASE_URL from "../../config/api";
// import ProcessorView from "@/views/ProcessorView.vue";

export default {
    name: "ProdutosList",

    components: {
        // ProcessorView
    },
    props: {
        id: {
            type: String
        }
    },
    data() {
        return {
            headers: [
                { text: 'Id', value: 'id' },
                {
                    text: 'Nome',
                    sortable: false,
                    value: 'nome',
                },
                // { text: 'Bem', value: 'bem' },
                { text: 'Descricao', value: 'descricao' },
                { text: 'Maxima', value: 'max' },
                { text: 'Mininma', value: 'min' },
                { text: 'Acoes', value: 'actions', sortable: false },
            ],
            dialog: true,
            selected: [],
            selectedIndexLocal: null,
            url: `${BASE_URL}/empresas/`,
            urlp: `${BASE_URL}/produtos`,
            produtos: [],
        };
    },
 
    methods: {

        getProdutos() {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }

            axios.get(this.url + this.$props.id, config)
                .then(data => {
                    this.produtos = data.data.produto.produtos;
                    console.log(data.data.produto);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                    if (error.response.status === 401) {
                        this.$router.push({ name: 'Admin_login' });
                        localStorage.removeItem('nome');
                        location.reload();
                    }
                })
                .finally(() => {
                    this.dialog = false; // Hide the dialog, regardless of success or error
                });
            // },
        },
        editarProduto(item) {
            this.$router.push({ name: 'editarProduto', params: { id: item.id, idEmp: this.$props.id } });
        },
        criarProduto() {
            this.$router.push({ name: 'CriarProduto', params: { id: this.$props.id } });
        },
        apagarProduto(item) {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }
            axios.delete(`${this.urlp}/${item.id}`, config)
                .then(response => {
                    console.log(response);
                    location.reload();
                })
                .catch((error) => {
                    console.error("Error submitting data:", error);
                    if (error == 401) {
                        this.$router.push({ name: 'Admin_login' });
                    }
                }
                );
        }
    },
    created() {
        // this.dialog=true;
        this.getProdutos();
    },
}
</script>
  