<template>
    <div>
        <form class="form" style="width: 600px; margin: 30px auto;">
          <v-text-field v-model="nome" label="Nome" required></v-text-field>
          <v-text-field v-model="dominio" label="Dominio" required></v-text-field>
          <v-text-field v-model="telefone" label="Telefone" required type="number"></v-text-field>
          <input type="file" @change="onFileChange" accept="image/*"><br>
          <v-btn class="mr-4" @click="submitForm" color="green lighten-2">Editar</v-btn>
          <v-btn @click="clearForm">Limpar</v-btn>
          <v-btn @click="cancelar" style="margin: 10px;" color="yellow lighten-3">Cancelar</v-btn>
        </form>
        <template>
            <div class="text-center">
                <v-dialog v-model="dialog" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                        <v-card-text>
                            Aguarde...
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </template>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import BASE_URL from "../../config/api";
  import Swal from 'sweetalert2';
  
  export default {
    name: "UptadeEmpresa",
   
    data() {
      return {
        url: `${BASE_URL}/empresas`,
        nome: "",
        dominio: "",
        telefone: null,
        logo: null,
        empresaId:this.$route.params.id,
        dialog:false
      };
    },
    created() {
      if (this.empresaId) {
        // Fetch the existing data of the empresa using the provided empresaId
        this.fetchEmpresaData();
      }
    },
    methods: {
        submitForm() {
          const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      }
      console.log(config);
            this.dialog=true;
            const formData = new FormData();
            formData.append("nome", this.nome);
            formData.append("dominio", this.dominio);
            formData.append("telefone", this.telefone);
            if(this.logo){
                formData.append("logo", this.logo);
            }    
            axios.post(`${this.url}/${this.empresaId}`, formData,config)
            .then((response) => {
                // Handle success, e.g., show a success message or redirect to a new page
                console.log("Data successfully submitted:", response.data);
                this.clearForm();
                this.dialog=false;
                Swal.fire({
                        title: "Actualizado!",
                        text: "Empresa Actualizada com sucesso!",
                        icon: "success",
                    });
                this.$router.push("/empresas/"+this.empresaId);
            })
            .catch((error) => {
                // Handle error, e.g., show an error message
                // this.$router.push({ name: 'Admin_login' })
                console.error("Error submitting data:", error);
                this.dialog=false;
                Swal.fire({
                        title: "Erro!",
                        text: "Ocorreu um erro!",
                        icon: "error",
                    });
            });
        },
      clearForm() {
        this.nome = "";
        this.dominio = "";
        this.telefone = null;
      },
      onFileChange(event) {
        this.logo = event.target.files[0]; // Store the selected image file
      },
      fetchEmpresaData() {
          const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      }
        // Assuming you have an API endpoint to fetch empresa data by ID
        axios.get(`${this.url}/${this.empresaId}`,config)
          .then((response) => {
            // Fill the form fields with the fetched data
            const empresaData = response.data.produto;
            this.nome = empresaData.nome;
            this.dominio = empresaData.dominio;
            this.telefone = empresaData.telefone;
            // Depending on how you store the logo, you may need to handle it differently
           
          })
          .catch((error) =>{
          console.error("Error submitting data:", error);
          if(error==401){
            this.$router.push({ name: 'Admin_login' });
          }
        } 
        );
      },
      cancelar() {
        this.$router.push({ name: 'verEmpresa', params: { id: this.empresaId} });
        },
    },
  };
  </script>
  
<style scoped>
.form{
    background-color: rgb(255, 241, 223);
    padding: 40px;
}
</style>
  