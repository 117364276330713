<template>
    <v-card class="mx-auto menu">
        <!-- <v-navigation-drawer permanent>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        Menu
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense nav >
                <v-list-item-content>
                    <router-link to="/registar">
                        <v-btn class="btn">
                            <v-list-item-title class="align">
                                <img width="25" height="25"
                                    src="https://img.icons8.com/external-anggara-basic-outline-anggara-putra/48/000000/external-add-friends-social-media-interface-anggara-basic-outline-anggara-putra.png"
                                    alt="external-add-friends-social-media-interface-anggara-basic-outline-anggara-putra" />
                                Add Admin</v-list-item-title>
                        </v-btn>
                    </router-link>
                </v-list-item-content>
                <v-list-item-content>
                    <router-link to="/users">
                        <v-btn class="btn">
                            <v-list-item-title class="align">
                                <img width="25" height="25" src="https://img.icons8.com/ios/50/000000/list--v1.png"
                                    alt="list--v1" />
                                ver Admins</v-list-item-title>
                        </v-btn>
                    </router-link>
                </v-list-item-content>
                <v-list-item-content>
                    <router-link to="/CriarEmpresa">
                        <v-btn class="btn">
                            <v-list-item-title class="align">
                                <img width="25" height="25" src="https://img.icons8.com/ios/50/add--v1.png" alt="add--v1" />
                                Empresa</v-list-item-title>
                        </v-btn>
                    </router-link>
                </v-list-item-content>
                <v-list-item-content>
                    <v-btn class="btn" @click="toggleCont">
                        <v-list-item-title class="align">
                            <img width="25" height="25" src="https://img.icons8.com/ios/100/000000/edit--v1.png"
                                alt="add--v1" />
                            PrimeRate</v-list-item-title>
                    </v-btn>
                </v-list-item-content>
            </v-list>
        </v-navigation-drawer> -->
        <div class="admin">
            <AdminPage />
        </div>
    </v-card>
</template>

<script>
import AdminPage from '../Admin/AdminPage.vue'
// import { mapMutations } from 'vuex';
import { mapState, mapMutations } from 'vuex';


export default {
    
    components: {
        AdminPage
    },
    data() {
        return {
            right: null,
        }
    },
    computed: {
        ...mapState(['cont']), // Mapeia o estado global para as propriedades computadas do componente       
    },
  
    methods: {
        ...mapMutations(['toggleCont']), //activa aexibicao do card primerate       
        ...mapMutations(['toggleDialog']), //activa aexibicao do processor       
    }
}
</script>
<style scoped>
.menu {
    float: left;
    height: 100% !important;
    width: 100% !important;
    display: flex;
}

.admin {
    /* float: right; */
    overflow-y: auto !important;
    /* margin: auto; */
    height: 100% !important;
    width: 100% !important;
}

img {
    margin: 0 10px;
}

.btn {
    width: 100% !important;
    background-color: white !important;
    box-shadow: none !important;
}

.align {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
