<template>
    <div style="display: flex;">
        <!-- <P>Prime Rate: {{ primerate }}</P> -->
        <!-- <v-btn style="margin: 0 20px;" @click="editar" id="myBtn"><img width="25" height="25"
                src="https://img.icons8.com/ios/100/000000/edit--v1.png" alt="edit--v1" /></v-btn> -->
        <v-card elevation="2" class="modal" v-if="getCont">
            <div class="modal-content">
                <div>
                    <h1>Actualizar o prime rate</h1>
                    <v-form>
                        <v-text-field type="number" v-model="primerate" label="Prime Rate %"></v-text-field>
                    </v-form>
                </div>
                <div>
                    <v-btn style="margin:0 5px;" @click="salvar()" color="green lighten-2">Salvar</v-btn>
                    <v-btn style="margin:0 5px;" @click="toggleCont" color="yellow lighten-3">Cancelar</v-btn>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import axios from "axios";
import BASE_URL from "../../config/api";
import { mapState, mapMutations } from 'vuex';
import Swal from 'sweetalert2';
// import { mapState } from 'vuex';


export default {
    name: "PrimeRate",

    data() {
        return {
            url: `${BASE_URL}/primerate`,
            primerate: "",
            id: 1,
            // visivel: false
        }
    },

    computed: {
        ...mapState(['cont']), // Mapeia o estado global para as propriedades computadas do componente
        getCont() {

            return this.cont ? true : false; // Converte o valor booleano para string 'true' ou 'false'
        },
    },

    methods: {
        ...mapMutations(['toggleCont']), // Mapeia a mutation 'toggleCont' para um método do componente
        // ...mapActions(['incrementAsync']), // Mapeia as actions para métodos do componente

        // fetchPrimeRate() {
        //     const config = {
        //         headers: {
        //             Authorization: `Bearer ${localStorage.getItem('token')}`,
        //         }
        //     }
        //     axios.get(`${this.url}`, config)
        //         .then((response) => {
        //             // Fill the form fields with the fetched data
        //             const primerate = response.data.primerate[0].primerate;
        //             this.primerate = primerate;
        //         })
        //         .catch((error) => {
        //             console.error("Error submitting data:", error);
        //             if (error == 401) {
        //                 localStorage.clear();
        //                 this.$router.push({ name: 'Admin_login' });
        //             }
        //         }
        //         );
        // },

        salvar() {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    }
                }
                const formData = new FormData();
                formData.append("primerate", this.primerate);
                axios.post(`${this.url}/${this.id}`, formData, config)
                    .then((response) => {
                        // Handle success, e.g., show a success message or redirect to a new page
                        console.log("Data successfully submitted:", response.data);
                        this.toggleCont();
                    })
                    .catch((error) => {
                        if (error == 401) {
                            localStorage.clear();
                            console.error("Error submitting data:", error);
                            this.$router.push({ name: 'Admin_login' });
                        }
                    }
                    );
                Swal.fire({
                    title: "Sucesso!",
                    text: "Primerate actualizado com sucesso!",
                    icon: "success",
                });
            } catch (error) {
                Swal.fire({
                    title: "Ups!",
                    text: "Erro ao actualizar o Primerate",
                    icon: "error",
                });
                if (error == 401) {
                    console.error("Error submitting data:", error);
                    localStorage.clear();
                    this.$router.push({ name: 'Admin_login' });
                }
            }
        }
    },
    created() {
        // this.fetchPrimeRate();
    }
}

</script>

<style scoped>
/* The Modal (background) */
.modal {
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    overflow: hidden;
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
}
</style>