<template>
  <form class="formulario" style="width: 500px; margin: 10px auto;">
    <v-text-field v-model="nome" label="Nome" required></v-text-field>
    <!-- <v-text-field v-model="bem" label="Bem" required></v-text-field> -->
    <v-text-field v-model="descricao" label="Descricao" required></v-text-field>
    <v-text-field v-model="taxa_max" label="taxa maxima" required></v-text-field>
    <v-text-field v-model="taxa_min" label="taxa minima" required></v-text-field>
    <v-text-field v-model="prazo_max" label="Prazo maximo" required></v-text-field>
    <v-text-field v-model="prazo_min" label="Prazo minimo" required></v-text-field>
    <v-text-field v-model="valor_max" label="valor maximo" required></v-text-field>
    <v-text-field v-model="valor_min" label="valor minimo" required></v-text-field>
    <!-- <input type="file" @change="onFileChange" accept="image/*"> -->
    <v-btn class="mr-4" @click="submitForm" color="green lighten-2">Salvar</v-btn>
    <v-btn @click="clearForm" color="orange lighten-2">Limpar</v-btn>
    <v-btn @click="cancelar" style="margin: 10px;" color="yellow lighten-3">Cancelar</v-btn>
  </form>
</template>
  
<script>
import axios from "axios";
import BASE_URL from "../../config/api";
import Swal from 'sweetalert2';

export default {
  name: "UptadeEmpresa",

  data() {
    return {
      url: `${BASE_URL}/produtos`,
      nome: "",
      // bem: "",
      descricao: "",
      taxa_max: null,
      taxa_min: null,
      prazo_max: null,
      prazo_min: null,
      valor_max: null,
      valor_min: null,
      // logo: null,
      produtoId: this.$route.params.id,
      empresaId: this.$route.params.idEmp
    };
  },
  created() {
    if (this.produtoId) {
      this.fetchProdutoData();
    }
  },
  methods: {
    submitForm() {
      if (!this.ehVazio()) {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        }
        // console.log('ok');

        const formData = new FormData();
        formData.append("nome", this.nome);
        formData.append("descricao", this.descricao);
        formData.append("taxa_max", this.taxa_max);
        formData.append("taxa_min", this.taxa_min);
        formData.append("prazo_max", this.prazo_max);
        formData.append("prazo_min", this.prazo_min);
        formData.append("max", this.valor_max);
        formData.append("min", this.valor_min);

        console.log(config);
        // return;    
        axios.post(`${this.url}/${this.produtoId}`, formData, config)
          .then((response) => {
            // Handle success, e.g., show a success message or redirect to a new page
            console.log("Data successfully submitted:", response.data);
            this.clearForm();
            this.$router.push("/empresas/" + this.$route.params.idEmp);
            Swal.fire({
                        title: "Sucesso!",
                        text: "Produto editado com sucesso!",
                        icon: "success",
                    });
          })
          .catch((error) => {
            Swal.fire({
                        title: "Ups!",
                        text: "Erro ao editar produto!",
                        icon: "error",
                    });
                    console.error("Error submitting data:", error);
          if (error == 401) {
            localStorage.clear();
            this.$router.push({ name: 'Admin_login' });
          }
        });
      }
    },
    clearForm() {
      this.nome = "";
      // this.bem = "";
      this.descricao = "";
      this.taxa_max = "";
      this.taxa_min = "";
      this.valor_max = "";
      this.valor_min = "";
      this.prazo_max = "";
      this.prazo_min = "";
    },
    ehVazio() {
      if ((this.nome == null || this.nome == "") || (this.descricao == null || this.descricao == "") ||
        (this.taxa_max == null || this.taxa_max == "") || (this.taxa_min == null || this.taxa_min == "") ||
        (this.valor_max == null || this.valor_max == "") || (this.valor_min == null || this.valor_min == "") ||
        (this.prazo_max == null || this.prazo_max == "") || (this.prazo_min == null || this.prazo_min == "")) {
        this.tvisible = true;
        this.texto = "Preecha todos os campos"
        setInterval(() => {
          this.tvisible = false;
        }, 5000);
        return true;
      } else {
        return false;
      }
    },
    // onFileChange(event) {
    //   this.logo = event.target.files[0]; // Store the selected image file
    // },
    fetchProdutoData() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      }
      // Assuming you have an API endpoint to fetch product data by ID
      axios.get(`${this.url}/${this.produtoId}`, config)
        .then((response) => {
          // Fill the form fields with the fetched data
          const produtoData = response.data.produto;
          this.nome = produtoData.nome;
          // this.bem = produtoData.bem;
          this.descricao = produtoData.descricao;
          this.taxa_min = produtoData.taxa_min;
          this.taxa_max = produtoData.taxa_max;
          this.prazo_min = produtoData.prazo_min;
          this.prazo_max = produtoData.prazo_max;
          this.valor_min = produtoData.min;
          this.valor_max = produtoData.max;
          // Depending on how you store the logo, you may need to handle it differently
        })
        .catch((error) => {
          console.error("Error submitting data:", error);
          if (error == 401) {
            localStorage.clear();
            this.$router.push({ name: 'Admin_login' });
          }
        }
        );
    },
    cancelar() {
      this.$router.push({ name: 'verEmpresa', params: { id: this.empresaId } });
    },
  },
};
</script>
<style scoped>
.formulario {
  background-color: rgb(255, 247, 216);
  padding: 30px;
}
</style>