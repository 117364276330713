<template>
  
  <div>
    <router-link to="/CriarEmpresa">
      <v-btn class="ma-2" color="#deac62" style="margin-left: 30px !important;">
        <v-icon dark left>
          mdi-office-building-plus
        </v-icon>Adiconar Empresa
      </v-btn>
    </router-link>
    <!-- <v-text-field v-model="search" append-icon="mdi-magnify" placeholder="Pesquisar Empresas . . ." single-line hide-details style="width: 1000px; margin: auto; padding: 10px;"></v-text-field> -->
    <v-data-table :headers="headers" :search="search" :items="empresas" @click:row="onRowClick" class="elevation-1"
      style="margin: 0 20px;"></v-data-table>
    <!-- <template>
      <div class="text-center">
        <v-dialog v-model="dialog" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Aguarde...
              <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </template> -->
  </div>
</template>

<script>
import axios from "axios";
import BASE_URL from "../../config/api";

// import { mapGetters } from 'vuex';
// import VerEmpresa from "./VerEmpresa.vue";

export default {
  name: "EmpresasList",
  // computed: {
  //   ...mapGetters(['serchPesquisa']),
  // },
  props: {
    search: {
      type: String,
      required: true
    }
  },

  components: {
  },
  data() {
    return {
      headers: [
        { text: 'Id', value: 'id' },
        {
          text: 'Nome',
          sortable: false,
          value: 'nome',
        },
        { text: 'Dominio', value: 'dominio' },
        { text: 'Telefone', value: 'telefone' },
        { text: 'Criado em', value: 'created_at' },
        { text: 'Atualizado em', value: 'updated_at' },
      ],
      url: `${BASE_URL}/empresas`,
      empresas: [],
      selected: [],
      dialog: false,
      // search:''
    };
  },

  methods: {
    getEmpresas() {
      this.$store.state.load = true
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      }
      axios.get(this.url, config).then(data => {
        this.empresas = data.data.empresas;
        // if(this.empresas){
        //   // this.dialog = false;
        // }
      }).finally(()=>{
          this.$store.state.load = false;

      })  .catch((error) => {
                    console.error("Error submitting data:", error);
                    if (error == 401) {
                        localStorage.clear();
                        this.$router.push({ name: 'Admin_login' });
                    }
                }
                );
    },

    onRowClick(item) {
      this.dialog = true;
      this.selected = [item];
      this.$router.push({ name: 'verEmpresa', params: { id: item.id } });
    },

    handle(data) {
      this.$emit('dados', data);
    }

  },

  created() {
    this.dialog = true;
    this.getEmpresas();    
  }
};
</script>
