<template>
    <div style="margin:50px  auto;">
        <!-- <div style="width: 100px; height: 100px; margin: auto;">
            <img width="100" height="100" src="https://img.icons8.com/ios-filled/100/000000/guest-male--v1.png"
                alt="guest-male--v1" />
        </div> -->
        <v-card
        class="mx-auto"
        width="500">
            <v-toolbar
                dark
                >
				<v-row>
                    <v-col cols="10">
                        Novo Admin
                    </v-col>
                
                </v-row>
			</v-toolbar>
            <v-card-text>
                <form class="form" v-if=!visivel>
                    <v-text-field v-model="nome" label="Nome" required type="text"></v-text-field>
                    <v-text-field v-model="email" label="Email" required type="email"></v-text-field>
                    <v-text-field v-model="apelido" label="Apelido" required type="text"></v-text-field>
                    <v-text-field v-model="password" label="password" required type="password"></v-text-field>
                </form>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="submit" color="orange lighten-2">Registar</v-btn>
                <router-link to="/users">
                    <v-btn class="ml-4" color="green lighten-2">Cancelar</v-btn>
                </router-link>

            </v-card-actions>
        </v-card>
        <!-- <template>
            <div class="text-center">
                <v-dialog v-model="dialog" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                        <v-card-text>
                            Aguarde...
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </template> -->
    </div>
</template>

<script>
import axios from "axios";
import BASE_URL from "../../config/api";
import Swal from 'sweetalert2';

export default {
    name: "RegistAdmin",
    data() {
        return {
            url: `${BASE_URL}/user`,
            email: null,
            password: null,
            nome: null,
            apelido: null,
            visivel: false,
            texto: null,
            dialog: false
        }
    },
    methods: {
        submit() {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }
            // this.dialog = true;
            this.$store.state.load = true
            const formData = new FormData();
            formData.append("nome", this.nome);
            formData.append("email", this.email);
            formData.append("password", this.password);
            formData.append("apelido", this.apelido);

            axios
                .post(this.url, formData, config)
                .then((response) => {
                    // Handle success, e.g., show a success message or redirect to a new page
                    console.log("Data successfully submitted:", response.data);
                    // this.texto = "Registado com sucesso!";
                    this.visivel = true;
                    this.dialog = false;
                    this.$store.state.load = false
                    Swal.fire({
                        title: "Registado!",
                        text: "Admin Registado com sucesso!",
                        icon: "success",
                    });
                    this.$router.push({ name: 'home' });
                })
                .catch((error) => {
                    // Handle error, e.g., show an error message
                    console.error("Error submitting data:", error);
                    // this.texto = "Cadastro sem sucesso!";
                    this.visivel = true;
                    this.dialog = false
                    this.$store.state.load = false
                    Swal.fire({
                        title: "Ups!",
                        text: "Erro ao criar Admin!",
                        icon: "error",
                    });

                    if (error == 401) {
                        this.$router.push({ name: 'Admin_login' });
                    }
                });
        },


    },
    created() {

    }
}

</script>

<style scoped>

</style>