<template>
    <div style="width: 300px; margin:50px  auto;">
        <div style="width: 100px; height: 100px; margin: auto;">
            <img width="100" height="100" src="https://img.icons8.com/ios-filled/100/000000/guest-male--v1.png"
                alt="guest-male--v1" />
        </div>
        <p>Coloque o teu email para receber as intrucoes de redefinicao da senha</p>
        <form style="width: 300px; margin: 10px auto;" v-if=visivel1>
            <v-text-field v-model="email" label="Email" required type="email"></v-text-field>
            <v-btn class="mr-4" @click="submit">Enviar</v-btn>
        </form>
        <v-card style="margin: 10px auto; padding: 30px;" v-if=visivel>
            <h3>{{ texto }}</h3>
        </v-card>
        <template>
            <div class="text-center">
                <v-dialog v-model="dialog" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                        <v-card-text>
                            Aguarde...
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </template>
    </div>
</template>

<script>
import axios from "axios";
import BASE_URL from "../../config/api";

export default {
    name: "FogotPassword",
    data() {
        return {
            email: null,
            url: `${BASE_URL}/user/verify/email`,
            visivel: false,
            visivel1: true,
            texto: null,
            dialog:false
        }
    },
    methods: {
        submit() {
            this.dialog=true;
            axios.post(this.url, {email: this.email}).then(data => {
                console.log('Verificar o email:', data.data);
                this.visivel = true
                this.visivel1=false
                this.texto = 'Uma mensagen de redefinicao da senha foi mandada por email. Verifique o teu email para mais intrucoes.'
                this.dialog=false
                //logar o user atravez do email
                // acessar a conta atravez do token...
            }).catch((error) => {
                this.visivel = true
                this.texto = 'Email nao foi encontrado !'
                this.dialog=false
                console.log(error);
            }

            );
        }
    }
}
</script>