<template>
    <!-- <div style="width: 300px; margin:50px  auto;">
        <div style="width: 100px; height: 100px; margin: auto;">
            <img width="100" height="100" src="https://img.icons8.com/ios-filled/100/000000/guest-male--v1.png"
                alt="guest-male--v1" />
        </div>
        <form style="width: 300px; margin: 10px auto;">
            <v-text-field v-model="email" label="Email" required type="email"></v-text-field>
            <v-text-field v-model="password" label="password" required type="password"></v-text-field>
            <v-btn class="mr-4" @click="submit">Entrar</v-btn>

            <router-Link to="/fogot">
                <a>Esqueci a senha</a>
            </router-Link>
        </form>
        <template>
            <div class="text-center">
                <v-dialog v-model="dialog" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                        <v-card-text>
                            Aguarde...
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
            <v-alert dense border="left" type="warning" v-show=isIncorrect>
                Dados Inseridos Estão incorrectos!
            </v-alert>
            <v-alert dense border="left" type="warning" v-show=estVazio>
                Preencha todos os campos!
            </v-alert>
        </template>
    </div> -->
    <v-container fill-hight style="height: 100vh;" class="mt-6">
        <v-row wrap justify="center" align="center" style="margin-top: 200px;">
            
            <v-card min-width="500px">
                <v-toolbar color="secondary" dark>
                    <v-row justify="center" align="center">
                        Simulador Administração
                    </v-row>
                </v-toolbar>
                <v-container>
                        <v-form @submit.prevent="submit">
                            
                                <v-card-text>
                                    <v-text-field
                                        v-model="email"
                                        label="Email"
                                    />
                                    <v-text-field
                                        v-model="password"
                                        label="Senha"
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show1 ? 'text' : 'password'"
                                        name="input-10-1"
                                        counter
                                        @click:append="show1 = !show1"
                                    >

                                    </v-text-field>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                    @click="submit"
                                    small
                                    elevation="4"
                                    dark
                                    color="#primary"
                                    >
                                        Entrar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                    depressed
                                    rounded
                                    text
                                    small
                                    to="/fogot">
                                        Esqueceu a senha?
                                    </v-btn>
                                </v-card-actions>
                            
                        </v-form>
                    </v-container>
            </v-card>
        </v-row>

    </v-container>
</template>

<script>
import axios from "axios";
import BASE_URL from "../../config/api";
import Swal from 'sweetalert2';

export default {
    name: "AdminLogin",
    data() {
        return {
            url: `${BASE_URL}/login`,
            email: null,
            password: null,
            dialog: false,
            estVazio: false,
            isIncorrect: false,
            show1: false
        };
    },
    methods: {
        submit() {
            this.$store.state.load = true
            if (this.email && this.password) {
                this.estVazio = false;
                this.dialog = true;
                this.isIncorrect = false;
                var data = { email: this.email, password: this.password };
                axios.post(`${this.url}`, data)
                    .then((response) => {
                        console.log(response.data.user);
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("nome", response.data.user.nome);
                        localStorage.setItem("email", response.data.user.email);
                        this.$store.state.load = false

                var originalRoute = this.$store.state.redirectTo;
                if (originalRoute) {
                    this.$router.push(originalRoute);
                    location.reload();
                }
                else {
                    this.$router.push("/home");
                    location.reload();
                }
            })
                    .catch(() => {
                // console.error("Error submitting data:", error);
                // this.dialog = false;
                // this.isIncorrect = true;
                this.$store.state.load = false
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Credencias Invalidas!",
                    });
            }
            );
    }else{
        this.estVazio = true;
    }
},
    },
created() {
    // location.reload();
},
}

</script>